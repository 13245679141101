import { GOOGLE_APIS } from '../common';

const FindGmailUser = async (token: string) => {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    };

    return await fetch(GOOGLE_APIS + token, requestOptions)
        .then(response => {
            return response.json();
        })
        .then(data => {
            return data.email;
        })
        .catch(error => {
            console.error(error);
        });
}

export default FindGmailUser