import React from 'react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import Google from '../google';
import { GOOGLE_CLIENT_ID } from '../../common';

const GoogleLogin = () => {
  return (
    <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
      <Google />
    </GoogleOAuthProvider>
  )
}

export default GoogleLogin