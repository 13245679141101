import { MODULES_ENDPOINT } from '../common';

export const FindLocations = async (setLocations: any, setErrorMessage: any) => {
    await fetch(MODULES_ENDPOINT)
        .then(async response => {
            return response.json();
        })
        .then(data => {
            setLocations(data);
            setErrorMessage("");
        })
        .catch(error => {
            setLocations([]);
            setErrorMessage('Servicio no disponible en este momento. Por favor intente nuevamente más tarde.');
        });
};