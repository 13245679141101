import React, { useContext } from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import FindGmailUser from '../../api/findGmailUser';
import GoogleG from '../../assets/GoogleG.png';
import { AccessTokenContext } from '../../common/context';
import './index.css';

const Google = () => {
  const {setAccessToken, setUsername} = useContext(AccessTokenContext);
  const login = useGoogleLogin({
    onSuccess: async codeResponse => {
      setAccessToken(codeResponse.access_token);
      const email = await FindGmailUser(codeResponse.access_token);
      setUsername(email);
    },
    onError: () => {console.error('Login Failed')}
  });

  return (
    <>
      <div className="col-4"></div>
      <div className="col-4">
        <a className="btn btn-outline-secondary" role="button" onClick={() => login()} style={{display: 'inline-block'}}>
          <img width="20px" style={{ marginBottom: "3px", marginRight: "5px" }} alt="Ingresar_con_Google" src={GoogleG} />
          Ingresar Con Google
        </a>
      </div>
      <div className="col-4"></div>
    </>
  )
}

export default Google