import { ACCOUNT_BY_NUMBER_ENDPOINT } from '../common';

export const FindAccountByNumber = async (accountNumber: string, setBalanceAccount: any, setShowBalance: any, setErrorMessage: any) => {
  const payload = {
    accountNumber: accountNumber
  };

  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(payload)
  };

  await fetch(ACCOUNT_BY_NUMBER_ENDPOINT, requestOptions)
    .then(response => {
      return response.json();
    })
    .then(data => {
      setBalanceAccount(data);
      setShowBalance(true);
      setErrorMessage("");
    })
    .catch(error => {
      setShowBalance(false);
      setBalanceAccount(undefined)
      setErrorMessage('Servicio no disponible en este momento. Por favor intente nuevamente más tarde.');
    });
}