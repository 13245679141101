import React from 'react'
import './index.css';

const Footer = () => {
  return (
    <footer>
        <a rel="license" href="http://sapap.gob.mx/avisodeprivacidad.html" target="_blank" className='link-text'>Aviso de Privacidad</a>
    </footer>
  )
}

export default Footer