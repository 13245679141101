import { CREATE_NEW_ACCOUNT_RELATION } from '../common';

const CreateNewAccountRelation = async (username: string, accountNumber: string, setAccounts: any, setErrorMessage: any) => {
    const payload = {
        userEmail: username,
        accountNumber: accountNumber,
    };

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload)
    };

    await fetch(CREATE_NEW_ACCOUNT_RELATION, requestOptions)
        .then(response => {
            return response.json();
        })
        .then(data => {
            setAccounts(data);
            setErrorMessage("");
        })
        .catch(error => {
            setErrorMessage('Servicio no disponible en este momento. Por favor intente nuevamente más tarde.');
        });
}

export default CreateNewAccountRelation