import React from 'react';
import { Link } from 'react-router-dom';
import Banner from '../../assets/banner.png';
import HomeButton from '../../components/homeButton';

function Home() {
    return (
        <>
            <div className="row g-0">
                <div className="col-lg-12 col-sm-12 col-md-12">
                    <div id="carouselExampleSlidesOnly" className="carousel slide" data-ride="carousel">
                        <div className="carousel-inner">
                            <div className="carousel-item active">
                                <img src={Banner} className="d-block w-100" alt="..." />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row g-0">
                <div className="col-5"></div>
                <div className="col-lg-3 col-sm-12 col-md-12">
                    <Link to="/saldo-de-cuenta">
                        <HomeButton
                            icon="Card"
                            title="Pagar sin Registro"
                            subtitle='Consulte su Último Saldo'
                        />
                    </Link>
                </div>
                <div className="col-4"></div>
                <div className="col-5"></div>
                <div className="col-lg-3 col-sm-12 col-md-12">
                    <Link to="/detalle-de-cuenta">
                        <HomeButton
                            icon="Envelope"
                            title="Ingresar con mi Cuenta"
                            subtitle='Obtenga su Recibo y Realice su Pago'
                        />
                    </Link>
                </div>
                <div className="col-4"></div>
                <div className="col-5"></div>
                <div className="col-lg-3 col-sm-12 col-md-12">
                    <Link to="/modulos-de-pago">
                        <HomeButton
                            icon="Geo"
                            title="Ubicaciones de Cobro"
                            subtitle='Consulte la localización y horarios'
                        />
                    </Link>
                </div>
                <div className="col-4"></div>
            </div>
        </>
    );
}

export default Home;