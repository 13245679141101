import React from 'react';
import './App.css';
import Footer from '../../components/footer';
import Home from '../home';
import Locations from '../locations';
import BalanceEmail from '../balanceEmail';
import BalanceNoEmail from '../balanceNoEmail';
import NotFound from '../404';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/saldo-de-cuenta" element={<BalanceNoEmail />} />
          <Route path="/detalle-de-cuenta" element={<BalanceEmail />} />
          <Route path="/modulos-de-pago" element={<Locations />} />
          <Route path="/" element={<Home />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
      <Footer />
    </div>
  );
}

export default App;
