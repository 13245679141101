import React from 'react';
import { CreditCardFill, Envelope, GeoAltFill } from "react-bootstrap-icons";
import './index.css';

const HomeButton = (props: { title: string, subtitle: string, icon: string }) => {
    return (
        <>
            <div className="card card-home">
                <div className="row g-0 card-container">
                    <div className="col-3 card-icon">
                        {props.icon === 'Card' ?
                            <CreditCardFill color="#1B4D98" size={25} />
                            : (
                                props.icon === 'Envelope' ?
                                    <Envelope color="#1B4D98" size={25} />
                                    :
                                    <GeoAltFill color="#1B4D98" size={25} />
                            )
                        }
                    </div>
                    <div className="col-9 card-body">
                        <h6 className="card-title">{props.title}</h6>
                        <p className='card-subtitle'>{props.subtitle}</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HomeButton;