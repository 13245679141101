import { ACCOUNT_BY_USERNAME_ENDPOINT } from '../common';

export const FindAccountsByUsername = async (userName: string, setAccounts: any, setErrorMessage: any) => {
  const payload = {
    username: userName
  };

  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(payload)
  };

  await fetch(ACCOUNT_BY_USERNAME_ENDPOINT, requestOptions)
    .then(response => {
      return response.json();
    })
    .then(data => {
      setAccounts(data);
      setErrorMessage("");
    })
    .catch(error => {
      setErrorMessage('Servicio no disponible en este momento. Por favor intente nuevamente más tarde.');
    });
}