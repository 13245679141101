import React, { useEffect, useState } from 'react';
import { FindAccountsByUsername } from '../../api/findAccountsByUsername';
import SapapLogo from '../../assets/SapapLogo.png';
import { IAccount } from '../../models/module.model';
import AccountData from '../../components/accountData';
import { BB_URL, PAYMENT_CONCEPT, PAYMENT_SERVICE_NUMBER } from '../../common';
import { Link } from 'react-router-dom';
import { FindReceiptByUsername } from '../../api/findReceiptByUsername';
import CreateNewAccountRelation from '../../api/createNewAccountRelation';
import GoogleLogin from '../../components/googleLogin';
import { AccessTokenContext } from '../../common/context';
import './index.css';

const BalanceEmail = () => {
  const [username, setUsername] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [accounts, setAccounts] = useState<IAccount[]>([]);
  const [selectedAccount, setSelectedAccount] = useState<any>({});
  const [newAccountNumber, setNewAccountNumber] = useState<string>("");
  const [displayNewAccountForm, setDisplayNewAccountForm] = useState<boolean>(false);
  const [accessToken, setAccessToken] = useState<string>("");

  useEffect(() => {
    if (username !== undefined && username !== "") {
      handleUsernameAccounts();
    }
  }, []);

  useEffect(() => {
    if (username !== undefined && username !== "") {
      handleUsernameAccounts();
    } else {
      onClearNewAccountComponents();
    }
  }, [username]);

  const handleUsernameAccounts = async () => {
    await FindAccountsByUsername(username, setAccounts, setErrorMessage);
  };

  const onClearNewAccountComponents = () => {
    setNewAccountNumber("");
    setSelectedAccount(undefined);
    setDisplayNewAccountForm(false);
    setErrorMessage("");
  }

  const handleChangeAccount = (e: any) => {
    onClearNewAccountComponents();

    let account = accounts.find(x => x.accountNumber === e);
    setSelectedAccount(account);
  }

  const onOpenWindow = (e: any) => {
    window.open('', 'popup', 'width=870,height=600,menubar=no, scrollbars=yes, directories=no');
  }

  const handleDownloadReceipt = async () => {
    const fileUrl = await FindReceiptByUsername(username, selectedAccount.invoiceId, setErrorMessage);
    if (fileUrl !== undefined) {
      const iframe = `<html><head><style>body, html {width: 100%; height: 100%; margin: 0; padding: 0}</style></head><body><iframe src="${fileUrl}" style="height:calc(100% - 4px);width:calc(100% - 4px)"></iframe></body></html>`;
      window.open("", "popup", "width=870,height=600,toolbar=no,menubar=no,scrollbars=yes,resizable=yes")?.document.write(iframe);
    } else if (errorMessage === "") {
      setErrorMessage("No se ha podido obtener el recibo. Por favor intente más tarde");
    }
  }

  const handleNewAccount = (e: any) => {
    e.preventDefault();
    handleChangeAccount("0");
    onClearNewAccountComponents();
    setDisplayNewAccountForm(true);
  }

  const onCreateNewAccount = async (e: any) => {
    e.preventDefault();
    await CreateNewAccountRelation(username, newAccountNumber, setAccounts, setErrorMessage);
    setDisplayNewAccountForm(false);
  }

  const handleAccountNumberInput = (e: any) => {
    if ((e.target.value.match(/^[0-9]*$/) && e.target.value.length <= 10)) {
      setNewAccountNumber(e.target.value)
    }
  };

  const handleCancelNewAccount = () => {
    onClearNewAccountComponents();
  }

  const renderGoogleLogin = () => {
    return (
      <AccessTokenContext.Provider value={{ setAccessToken, setUsername }}>
        <GoogleLogin />
        <div className="row g-0 mt-5">
          <div className="col-lg-5 col-sm-12 col-md-12"></div>
          <div className="col-lg-2 col-sm-12 col-md-12">
            <Link to="/" className='button-group secondary-button'>
              Regresar
            </Link>
          </div>
          <div className="col-lg-5 col-sm-12 col-md-12"></div>
        </div>
      </AccessTokenContext.Provider>
    )
  }

  const renderActionButtons = () => {
    return (
      <form method="POST" action={BB_URL} target="popup" onSubmit={onOpenWindow}>
        <br />
        <Link to="/" className='button-group secondary-button'>
          Regresar
        </Link>

        <input type="button" value="Descargar Recibo" className='primary-button page-button' onClick={handleDownloadReceipt} />
        <input type="submit" value="Realizar Pago" className='primary-button' disabled={
                      selectedAccount.hash === undefined ||
                      selectedAccount.hash === '' ||
                      selectedAccount.contractStatus !== 'VIGENTE' ||
                      isNaN(selectedAccount.total) ||
                      parseFloat(selectedAccount.total) <= 0.0 } />
        <input type="hidden" name="cl_folio" value={selectedAccount.folio} />
        <br />
        <input type="hidden" name="cl_referencia" value={'PAGOWEB' + selectedAccount.accountNumber} />
        <br />
        <input type="hidden" name="cl_concepto" value={PAYMENT_CONCEPT} />
        <br />
        <input type="hidden" name="dl_monto" value={selectedAccount.total} />
        <br />
        <input type="hidden" name="servicio" value={PAYMENT_SERVICE_NUMBER} />
        <br />
        <input type="hidden" name="hash" value={selectedAccount.hash} />
        <br />
      </form>
    );
  }

  const renderNewAccountForm = () => {
    return (
      <div className="row g-0 mt-5">
        <div className="col-4"></div>
        <div className="col-4">
          <form method="POST" onSubmit={onCreateNewAccount}>
            <div className="row g-0">
              <div className="col-2"></div>
              <div className="col-lg-8 col-sm-12 col-md-12">
                <label htmlFor="accountNumberInput" className="field-label">NUEVO NUMERO DE CUENTA</label>
                <input
                  id="accountNumberInput"
                  type="input"
                  className="form-control"
                  value={newAccountNumber}
                  onChange={handleAccountNumberInput}
                  placeholder="0000000000" />
                <p className="hint-text">Tip: Ingrese los 10 números de su cuenta</p>
              </div>
              <div className="col-2"></div>
            </div>
            <div className="row g-0 mt-3">
              <div className="col-lg-12 col-sm-12 col-md-12 ">
                <button
                  className="secondary-button page-button"
                  onClick={handleCancelNewAccount}
                  type="button">Cancelar</button>
                <button
                  className="primary-button page-button"
                  disabled={newAccountNumber.length !== 10}
                  type="submit">Agregar</button>
              </div>
            </div>
          </form>
        </div>
        <div className="col-4"></div>
      </div>
    );
  }

  const renderAccountLists = () => {
    return (
      <>
        <div className="col-4"></div>
        <div className="col-4">
          <form>
            <div className="row g-0">
              <div className="col-lg-7 col-sm-12 col-md-12 align-self-end">
                <label htmlFor="accountNumberSelect">NUMERO DE CUENTA</label>
                <select id="accountNumberSelect" value={selectedAccount !== undefined ? selectedAccount.accountNumber : 0} onChange={(e) => handleChangeAccount(e.target.value)} className="react-select-container form-control">
                  <option value="0">{'Seleccione'}</option>
                  {accounts.map((acc, index) => (
                    <option key={index} value={acc.accountNumber}>{acc.accountNumber}</option>
                  ))}
                </select>
              </div>
              <div className="col-lg-5 col-sm-12 col-md-12 align-self-end">
                <button onClick={handleNewAccount} className="primary-button" type="button">Agregar Cuenta</button>
              </div>
              <p className="hint-text">Tip: Seleccione La Cuenta A Consultar O Agregue Una Nueva</p>
            </div>
          </form>
        </div>
        <div className="col-4"></div>

        {displayNewAccountForm &&
          renderNewAccountForm()
        }
        <div className="row g-0 mt-5">
          <div className="col-2"></div>
          <div className="col-8">
            <div className="row g-0 column-align">
              {selectedAccount !== undefined && selectedAccount.details !== undefined && selectedAccount.details.length > 0
                && selectedAccount.details.map((data: { title: string; value: string; }, index: number) => (
                  <AccountData key={index} keyvalue={index} title={data.title} value={data.value} />
                ))}
            </div>
          </div>
          <div className="col-2"></div>
        </div>

        <div className="row g-0 mt-5">
          <div className="col-3"></div>
          <div className="col-lg-6">
            {errorMessage !== undefined && errorMessage !== "" &&
              <p className="error-message">{errorMessage}</p>}
            {selectedAccount !== undefined && Object.keys(selectedAccount).length > 0 ?
              renderActionButtons()
              :
              <Link to="/" className='button-group secondary-button'>
                Regresar
              </Link>
            }
          </div>
          <div className="col-3"></div>
        </div>
      </>
    );
  }

  return (
    <>
      <div className="row g-0">
        <div className="col-1"></div>
        <div className="col-10 min-vh-100">
          <div className="card main-card main-card-body">
            <div className="row g-0">
              <div className="col-12 mb-5">
                <Link to="/">
                  <img src={SapapLogo} className="card-img-top sapap-logo" alt="sapap_logo" />
                </Link>
              </div>

              {accessToken === undefined || accessToken === ""
                ? renderGoogleLogin()
                : renderAccountLists()
              }
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default BalanceEmail