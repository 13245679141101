import React from 'react';
import './index.css';

const ModuleLocation = ({nameModule, content, schedule}: {nameModule: string, content: string, schedule: string}) => {
    return (
        <div className="col-12 col-sm-6 col-md-4 module">
            <p className="module-title">{nameModule}</p>
            <p className="module-content">{content}</p>
            <p className="module-content">{schedule}</p>
        </div>
    )
}

export default ModuleLocation