
const DOMAIN = 'https://sapap.com.mx';
//const DOMAIN = 'http://localhost:8081';

export const MODULES_ENDPOINT = DOMAIN + '/sapap-service/api/v1/modules';
export const ACCOUNT_BY_NUMBER_ENDPOINT = DOMAIN + '/sapap-service/api/v1/accounts/number';
export const ACCOUNT_BY_USERNAME_ENDPOINT = DOMAIN + '/sapap-service/api/v1/accounts/detail/username';
export const RECEIPT_BY_USERNAME_ENDPOINT = DOMAIN + "/sapap-service/api/v1/accounts/receipt?receiptId="
export const CREATE_NEW_ACCOUNT_RELATION = DOMAIN + "/sapap-service/api/v1/accounts/save"
export const BB_URL = 'https://multipagos.bb.com.mx/Estandar/index2.php';
export const GOOGLE_APIS = "https://www.googleapis.com/oauth2/v3/userinfo?access_token=";

export const PAYMENT_SERVICE_NUMBER = "326";
export const PAYMENT_CONCEPT = "1";
export const GOOGLE_CLIENT_ID = "877480468730-s3gvcsu0a5rg3i9hi80qhe7n5da9ekng.apps.googleusercontent.com";