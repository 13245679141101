import { RECEIPT_BY_USERNAME_ENDPOINT } from '../common';

export const FindReceiptByUsername = async (username: string, receiptId: any, setErrorMessage: any) => {
    
      const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/pdf' }
      };
      
      return await fetch(RECEIPT_BY_USERNAME_ENDPOINT + receiptId + '&username=' + username , requestOptions)
        .then(response => {
          setErrorMessage("");
          return response.blob();
        })
        .then(blob => {
          return window.URL.createObjectURL(blob);
        })
        .catch(error => {
          setErrorMessage('Servicio no disponible en este momento. Por favor intente nuevamente más tarde. [' + error + ']');
        });
}