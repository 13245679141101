import React from 'react';
import './index.css';

const accountData = (props: any) => {
    const { title, value } = props;
    return (
            <div className="col-lg-4 col-sm-12 col-md-12">
                <label className="account-data-title" >{title}</label>
                <br/>
                <label className="account-data-value" >{value}</label>
            </div>
    )
}

export default accountData