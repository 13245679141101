import React, { useState, useEffect } from 'react';
import SapapLogo from '../../assets/SapapLogo.png';
import { IModel } from '../../models/module.model';
import ModuleLocation from '../../components/moduleLocation';
import { Link } from 'react-router-dom';
import { FindLocations } from '../../api/findLocations';

const Locations = () => {
  const [locations, setLocations] = useState<IModel[]>([]);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (locations.length <= 0) {
      FindLocations(setLocations, setErrorMessage);
    }
  }, []);

  const modules = locations.filter(x => x.type === 'MODULE' && !x.deleted);
  const thirdParties = locations.filter(x => x.type === 'THIRDPARTY' && !x.deleted).map(x => x.name).join(", ");

  return (
    <>
      <div className="row g-0">
        <div className="col-1"></div>
        <div className="col-10 min-vh-100">
          <div className="card main-card main-card-body">
            <div className="row g-0">
              <div className="col-12 mb-5">
                <Link to="/">
                  <img src={SapapLogo} className="card-img-top sapap-logo" alt="sapap_logo" />
                </Link>
              </div>
              {
                modules.map(x => {
                  return <ModuleLocation
                    key={x.id}
                    nameModule={x.name ?? ""}
                    content={x.address ?? ""}
                    schedule={x.schedule ?? ""} />
                })
              }
              {
                Boolean(thirdParties)
                  ? <ModuleLocation
                    nameModule={"Establecimientos"}
                    content={thirdParties}
                    schedule={""} />
                  : <></>
              }
              {errorMessage !== undefined && errorMessage !== "" &&
              <p className="error-message">{errorMessage}</p>}
            </div>
            <div className="row g-0 mt-5">
              <div className="col-lg-5 col-sm-12 col-md-12"></div>
              <div className="col-lg-2 col-sm-12 col-md-12">
                <Link to="/" className='button-group secondary-button'>
                  Regresar
                </Link>
              </div>
              <div className="col-lg-5 col-sm-12 col-md-12"></div>
            </div>
            

            {/* <div className="row g-0 align-items-end">
              <div className="col-12 button-col">
                <Link to="/" className='button-group'>
                  Regresar
                </Link>
              </div>
            </div> */}
          </div>
        </div>
        <div className="col-1"></div>
      </div>
    </>
  )
}

export default Locations