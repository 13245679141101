import React, { useState } from 'react';
import SapapLogo from '../../assets/SapapLogo.png';
import { Link } from 'react-router-dom';
import './index.css';
import { FindAccountByNumber } from '../../api/findAccountByNumber';
import { BB_URL, PAYMENT_CONCEPT, PAYMENT_SERVICE_NUMBER } from '../../common';

const BalanceNoEmail = () => {
  const [accountNumber, setAccountNumber] = useState<string>("");
  const [balanceAccount, setBalanceAccount] = useState<any>({});
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [showBalance, setShowBalance] = useState(false);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    FindAccountByNumber(accountNumber, setBalanceAccount, setShowBalance, setErrorMessage);
  };

  const handleAccountNumberInput = (e: any) => {
    if ((e.target.value.match(/^[0-9]*$/) && e.target.value.length <= 10)) {
      setAccountNumber(e.target.value)
    }
  };

  const onOpenWindow = (e: any) => {
    window.open('', 'popup', 'width=870,height=600,menubar=no, scrollbars=yes, directories=no');
  }

  return (
    <>
      <div className="row g-0">
        <div className="col-1"></div>
        <div className="col-10 min-vh-100">
          <div className="card main-card main-card-body">
            <div className="row g-0">
              <div className="col-12 mb-5">
                <Link to="/">
                  <img src={SapapLogo} className="card-img-top sapap-logo" alt="sapap_logo" />
                </Link>
              </div>
              <div className="col-4"></div>
              <div className="col-4">
                <form onSubmit={handleSubmit}>
                  <div className="row g-0">
                    <div className="col-lg-8 col-sm-12 col-md-12 align-self-end">
                      <label htmlFor="accountNumberInput">NUMERO DE CUENTA</label>
                      <input
                        id="accountNumberInput"
                        type="input"
                        className="form-control"
                        value={accountNumber}
                        onChange={handleAccountNumberInput}
                        placeholder="0000000000" />
                        
                    </div>
                    <div className="col-lg-4 col-sm-12 col-md-12 align-self-end">
                      <button
                        className="primary-button"
                        disabled={accountNumber.length !== 10}
                        type="submit">Buscar</button>
                    </div>
                    <p className="hint-text">Tip: Ingrese los 10 números de su cuenta</p>
                  </div>
                </form>
              </div>
              <div className="col-4"></div>
            </div>
            <div className="row g-0 mt-5">
              <div className="col-4"></div>
              <div className="col-4">
                {showBalance && balanceAccount !== undefined ? <>
                  <p className="module-title">NÚMERO DE CUENTA:</p>
                  <p className="module-content">{balanceAccount.accountNumber}</p>
                  <br/>
                  <p className="module-title">TOTAL:</p>
                  <p className="module-content">${balanceAccount.total}</p></>
                  : <></>
                }
                {errorMessage !== undefined && errorMessage.trim() !== "" &&
                  <p className="error-message">{errorMessage}</p>
                }
              </div>
              <div className="col-4"></div>
            </div>
            <div className="row g-0 mt-3">
              <div className="col-4"></div>
              <div className="col-4">
                {showBalance && balanceAccount !== undefined ?

                  <form method="POST" action={BB_URL} target="popup" onSubmit={onOpenWindow}>
                    <br />
                    <Link to="/" className='button-group secondary-button'>
                      Regresar
                    </Link>
                    <input type="submit" value="Realizar Pago" className='primary-button' disabled={
                      balanceAccount.hash === undefined ||
                      balanceAccount.hash === '' ||
                      balanceAccount.contractStatus !== 'VIGENTE' ||
                      isNaN(balanceAccount.total) ||
                      parseFloat(balanceAccount.total) <= 0.0 } />
                    <input type="hidden" name="cl_folio" value={balanceAccount.folio} />
                    <br />
                    <input type="hidden" name="cl_referencia" value={'PAGOWEB' + balanceAccount.accountNumber} />
                    <br />
                    <input type="hidden" name="cl_concepto" value={PAYMENT_CONCEPT} />
                    <br />
                    <input type="hidden" name="dl_monto" value={balanceAccount.total} />
                    <br />
                    <input type="hidden" name="servicio" value={PAYMENT_SERVICE_NUMBER} />
                    <br />
                    <input type="hidden" name="hash" value={balanceAccount.hash} />
                    <br />
                  </form>

                  : <Link to="/" className='button-group secondary-button'>
                      Regresar
                    </Link>
                }
              </div>
              <div className="col-4"></div>
            </div>
          </div>
        </div>
        <div className="col-1"></div>
      </div>
    </>
  )
}

export default BalanceNoEmail